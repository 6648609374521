// Packages
import { useState, useEffect, useCallback } from "react";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";

// Helpers
import useHelpers from "../../../../helpers";

// Helpers
import useModels from "../../../../models";

// Actions
import useApi from "../../../../api";

// Components
import useComponents from "../../../../views/components";

const useTaskCreate = () => {
  const { id: idTask } = useParams();
  const navigation = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [nameMission, setNameMission] = useState(null);
  const [disableFields, setDisableFields] = useState(false);
  const [disableCloseNumberUser, setDisableCloseNumberUser] = useState({ index: 0, list: [true] });
  const [listCountries, setListCountries] = useState([]);
  const [listCurrencies, setListCurrencies] = useState([]);
  const [listProject, setListProject] = useState([]);
  const [listGroupMembeer, setListGroupMembeer] = useState([]);
  const [listTools, setListTools] = useState([]);
  const [listSelectGroup, setListSelectGroup] = useState({ index: 0, list: [] });
  const [listSelectTool, setListSelectTool] = useState({ index: 0, list: [] });
  const [disableFieldProyect, setDisableFieldProyect] = useState(false);
  const [disableFieldCountry, setDisableFieldCountry] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [typeTool, setTypeTool] = useState(null);

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useGeneral, useToast } = useQuickFunctions();
  const { newTaskValidationShema, FilterTaskValidationShema } = useValidators();
  const { dateFormatUTC, formatDate } = useGeneral();
  const { success } = useToast();

  // Models
  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors, useProjectsSelectors } = useSelectors();
  const { session } = useAuthSelectors();
  const { projectSelected } = useProjectsSelectors();
  const project = useSelector(projectSelected);

  const { rol } = useSelector(session);

  // Actions
  const { useActions } = useApi();
  const {
    useGeneralActions,
    useMissionActions,
    useProjectsActions,
    useGroupsActions,
    dispatch,
    useToolsActions,
  } = useActions();
  const { actListCountries } = useGeneralActions();
  const { actGetMission, actCreateMission, actEditMission } = useMissionActions();
  const { actListProjectsMission } = useProjectsActions();
  const { actListGroupsMision } = useGroupsActions();
  const { actListTools } = useToolsActions();

  // Components
  const { Toast } = useComponents();

  const { control, handleSubmit, reset, watch, setError, setValue, getValues, clearErrors } = useForm({
    resolver: yupResolver(newTaskValidationShema),
    defaultValues: {
      reminders: [{ message: "", date: "", time: "", ampm: "am" }],
      points: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "reminders",
  });
  const { fields: fieldsPoint, insert: insertPoints } = useFieldArray({
    control,
    name: "points",
  });
  const { fields: fieldsConfig } = useFieldArray({
    control,
    name: "sample_size",
  });
  const reminders = useWatch({ control, name: "reminders" });
  const startDate = useWatch({ control, name: "startDate" });
  const startTime = useWatch({ control, name: "startTime" });
  const startAmpm = useWatch({ control, name: "startAmpm" });

  const { control: controlFilter, handleSubmit: handleSubmitFilter } = useForm({
    resolver: yupResolver(FilterTaskValidationShema),
  });
  const { control: controlSearch, reset: resetSearch } = useForm();

  const handleCloseModal = () => {
    setShowModal(false);
    handleBack();
    /* if (location.pathname.includes("/misiones/editar")) {
      handleBack();
    } else {
      resetData();
    } */
  };

  const resetData = () => {
    reset();
  };
  const handleBack = () => {
    navigation(-1);
  };

  const onSubmitSearchProject = (e) => {
    if (e.target.value.length > 0) {
      actListProjectsMission({ page: 1, search: e.target.value }, prepareDataProject);
    } else {
      setListProject([]);
    }
  };

  const onChangeSearchProject = useCallback(debounce(onSubmitSearchProject, 750), []);

  const setDefaultData = (data) => {
    if (location.pathname.includes("/misiones/detalle")) {
      setDisableFields(true);
    }

    setListSelectGroup({ index: 0, list: data.groups });
    setListSelectTool({ index: 0, list: data.tools });
    setDisableFieldCountry(data.project_id !== null);

    reset({
      name: data.name,
      project_id:
        data.project_id !== null ? { id: data.project?.id, label: data.project?.name } : null,
      country_id:
        data.project_id !== null
          ? data.project?.countries?.map((v) => {
              return { label: v.name, value: v.id };
            })
          : data.countries?.map((v) => {
              return { label: v.name, value: v.id };
            }),
      currency: data.currency,
      description: data.description,
      points: data.points,
      app_points: data.app_points,
      points_conversion: data.points_conversion,
      app_init_text: data.app_init_text,
      app_end_text: data.app_end_text,
      mission_completed_text: data.mission_completed_text,
      mission_not_completed_text: data.mission_not_completed_text,
      reminders: data.reminders?.map((reminder) => {
        return {
          message: reminder.message,
          date: reminder.date,
          time: reminder.date,
          ampm: parseInt(reminder.date.split(" ")[1].split(":")[0]) > 12 ? "pm" : "am",
        };
      }),
      autoclose_cap: data.autoclose_cap,
      full_members_text: data.full_members_text,
      startDate: data.init_date.split("T")[0],
      startTime: data.init_date,
      startAmpm: parseInt(formatDate(data.init_date, "HH")) > 12 ? "pm" : "am",
      endDate: data.end_date.split("T")[0],
      endTime: new Date(data.end_date),
      endAmpm: parseInt(formatDate(data.end_date, "HH")) > 12 ? "pm" : "am",
      sample_size: data.sample_size?.map((v, i) => {
        const newList = disableCloseNumberUser.list;
        newList[i] = !v.autoclose_cap > 0;
        setDisableCloseNumberUser({ index: i, list: newList });
        return {
          checkCloseNumberUser: v.autoclose_cap > 0,
          sample_proportion: v.sample_proportion,
          country_name: v.country_name,
          country_id: v.country_id,
          autoclose_cap: v.autoclose_cap,
        };
      }),
    });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const onSubmitSuccess = () => {
    success(<Toast text={"¡Misión editada con éxito!"} />, {
      toastId: 0,
    });
    navigation(-1);
  };

  const onSubmitError = () => {
    setSaving(false);
  };

  const onSubmit = (data, e) => {
    if (
      !data.checkCloseNumberUser ||
      (data.checkCloseNumberUser &&
        data.full_members_text &&
        data.autoclose_cap &&
        data.autoclose_cap > 0)
    ) {
      setSaving(true);
      e.preventDefault();
      if (location.pathname.includes("/misiones/editar")) {
        actEditMission(
          idTask,
          {
            ...data,
            status: "in_course",
            end_date: dateFormatUTC([data.endDate, data.endTime, data.endAmpm]),
            init_date: dateFormatUTC([data.startDate, data.startTime, data.startAmpm]),
            country_id: data.country_id.map((country) => {
              return country.value;
            }),
            project_id: data.project_id?.id,
            reminders: data.reminders
              ? data.reminders.map((reminder) => {
                  return {
                    message: reminder.message,
                    date: dateFormatUTC([reminder.date, reminder.time, reminder.ampm]),
                  };
                })
              : [],
            groups: listSelectGroup.list.map((v) => {
              return { id: v.id, type: v.type };
            }),
            tools: listSelectTool.list.map((v) => {
              return v.id;
            }),
            sample_size: data.sample_size?.map((v) => {
              return {
                autoclose_cap: v.autoclose_cap,
                sample_proportion: v.autoclose_cap > 0 ? v.sample_proportion : false,
                checkCloseNumberUser: v.autoclose_cap > 0,
                country_id: v.country_id,
              };
            }),
          },
          onSubmitSuccess,
          onSubmitError
        );
      } else {
        setNameMission(data.name);
        actCreateMission(
          {
            ...data,
            status: "in_course",
            end_date: dateFormatUTC([data.endDate, data.endTime, data.endAmpm]),
            init_date: dateFormatUTC([data.startDate, data.startTime, data.startAmpm]),
            country_id: data.country_id.map((country) => {
              return parseInt(country.value);
            }),
            project_id: data.project_id?.id,
            reminders: data.reminders?.map((reminder) => {
              return {
                message: reminder.message,
                date: dateFormatUTC([reminder.date, reminder.time, reminder.ampm]),
              };
            }),
            groups: listSelectGroup.list.map((v) => {
              return { id: v.id, type: v.type };
            }),
            tools: listSelectTool.list.map((v) => {
              return v.id;
            }),
            sample_size: data.sample_size?.map((v) => {
              return {
                autoclose_cap: v.autoclose_cap,
                sample_proportion: v.autoclose_cap > 0 ? v.sample_proportion : false,
                checkCloseNumberUser: v.autoclose_cap > 0,
                country_id: v.country_id,
              };
            }),
          },
          handleShowModal,
          onSubmitError
        );
      }
    } else {
      if (!data.autoclose_cap || data?.autoclose_cap < 0)
        setError("autoclose_cap", { type: "custom", message: "Campo requerido" });
      if (!data.full_members_text)
        setError("full_members_text", { type: "custom", message: "Campo requerido" });
    }
  };

  const onSubmitFilter = (data, e) => {
    e.preventDefault();
    //console.log(data, e);
  };

  const loadData = (data) => {
    setListGroupMembeer(
      data.data.map((v) => {
        return { ...v, label: v.name, value: v.id };
      })
    );
  };

  const onSubmitSearch = (e) => {
    if (e.target.value.length >= 3) {
      const data = {
        countries: watch("country_id").map((v) => {
          return v.value;
        }),
      };
      actListGroupsMision({ search: e.target.value, data }, loadData);
    }
  };

  const loadDataTool = (data) => {
    setListTools(
      data.data.map((v) => {
        return { ...v, label: v.name, value: v.id };
      })
    );
  };
  const onSubmitSearchTool = (e) => {
    if (e.target.value.length >= 3) {
      dispatch(
        actListTools({ page: 1, search: e.target.value, mission: 0, publish: 1 }, loadDataTool)
      );
    }
  };

  const prepareDataCountry = (data) => {
    setListCountries(
      data.map((v) => {
        return { label: v.name, value: v.id, currency: v.currency };
      })
    );

    const prevCurrency = [...new Set(data.map((v) => v.currency))].map((v) => {
      return { label: v, value: v };
    });
    /* prevCurrency.push({label: 'MULTIPAIS', value: 'MULTI', disabled: true}) */
    setListCurrencies(prevCurrency);
  };

  const prepareDataProject = (data) => {
    setListProject(
      data.data.map((v) => {
        return { ...v, label: v.name, value: v.id };
      })
    );
  };

  const handleChangeCloseUser = (index, value) => {
    const newList = disableCloseNumberUser.list;
    newList[index] = !value;
    setValue(`sample_size[${index}].autoclose_cap`, 0);
    setValue(`sample_size[${index}].sample_proportion`, false);
    setDisableCloseNumberUser({ index: index, list: newList });
  };

  const onChangeSearchGroup = useCallback(debounce(onSubmitSearch, 750), []);
  const onChangeSearchTool = useCallback(debounce(onSubmitSearchTool, 750), []);

  const onSelectGroup = (value) => {
    const auxListSelect = listSelectGroup.list;
    if (
      value !== null &&
      auxListSelect.filter((v) => v.id === value.id && v.type === value.type).length === 0
    ) {
      auxListSelect.push(value);
      setListSelectGroup({ index: value.id, list: auxListSelect });
      resetSearch({ search: "" });
    }
  };

  const onSelectTool = (value) => {
    const auxListSelect = listSelectTool.list;
    if (value !== null && auxListSelect.filter((v) => v.id === value.id).length === 0) {
      auxListSelect.push(value);
      setListSelectTool({ index: value.id, list: auxListSelect });
      resetSearch({ searchTool: "" });
    }

    if (!typeTool) setTypeTool(value.type_tool);
  };

  const handleDeleteGroup = (id) => {
    const auxListSelect = listSelectGroup.list;
    setListSelectGroup({ index: id * -1, list: auxListSelect.filter((v) => v.id !== id) });
  };

  const handleDeleteTool = (id) => {
    const auxListSelect = listSelectTool.list;
    setListSelectTool({ index: id * -1, list: auxListSelect.filter((v) => v.id !== id) });
  };

  const compareDates = () => {
    const startDateMission = getValues("startDate");
    const startTimeMission = getValues("startTime");
    const startAmpmMission = getValues("startAmpm");
    const reminders = getValues("reminders");

    const startDateTimeMission = dateFormatUTC([
      `${startDateMission}`,
      `${startTimeMission}`,
      `${startAmpmMission}`,
    ]);

    const threeDaysBeforeStart = new Date(startDateTimeMission);
    threeDaysBeforeStart.setDate(threeDaysBeforeStart.getDate() - 3);

    const remindersOutOfDate = reminders
      .map((reminder, index) => ({ ...reminder, index }))
      .filter((reminder) => {
        const reminderDate = dateFormatUTC([
          `${reminder.date}`,
          `${reminder.time}`,
          `${reminder.ampm}`,
        ]);
        return new Date(reminderDate) < threeDaysBeforeStart;
      });

    return remindersOutOfDate;
  };

  const toggleCloseShowWarningModal = () => {
    setShowWarningModal(false);
  };

  useEffect(() => {
    actListCountries(prepareDataCountry);
    if (!!idTask) {
      actGetMission(idTask, setDefaultData);
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      //if (name === "checkCloseNumberUser") handleChangeCloseUser(value.checkCloseNumberUser);
      if (name === "project_id" && !!value?.project_id) {
        const listPoints = value.project_id?.countries?.map((v) => {
          return {
            country_name: v.name,
            country_id: v.id,
            app_points: 0,
            currency: v.currency,
            points_conversion: 0,
          };
        });
        const listConfig = value.project_id?.countries?.map((v, i) => {
          handleChangeCloseUser(i, false);
          return {
            country_name: v.name,
            country_id: v.id,
            checkCloseNumberUser: false,
            autoclose_cap: 0,
            sample_proportion: false,
          };
        });
        reset({
          ...value,
          sample_size: listConfig,
          country_id:
            value.project_id === null
              ? []
              : value.project_id?.countries?.map((v) => {
                  return { ...v, label: v.name, value: v.id };
                }),
          points: listPoints,
        });

        setDisableFieldCountry(true);
      }
      if (name === "project_id" && !value?.project_id) {
        setDisableFieldCountry(false);
      }
      if (name === "country_id") {
        const listPoints = value.country_id.map((v) => {
          return {
            country_name: v.label,
            country_id: v.value,
            app_points: 0,
            currency: v.currency,
            points_conversion: 0,
          };
        });
        const listConfig = value.country_id.map((v, i) => {
          handleChangeCloseUser(i, false);
          return {
            country_name: v.label,
            country_id: v.value,
            checkCloseNumberUser: false,
            autoclose_cap: 0,
            sample_proportion: false,
          };
        });
        reset({ ...value, points: listPoints, sample_size: listConfig });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (location.state?.from === "create_project") {
      setDisableFieldProyect(true);
      reset({
        project_id: { id: project.id, label: project.name },
        country_id: project?.countries?.map((v) => {
          return { label: v.name, value: v.id };
        }),
        reminders: [{ message: "", date: "", time: "", ampm: "am" }],
        currency: project.countries.length === 1 ? project.countries[0].currency : "MULTI",
      });
    }
  }, [location]);

  useEffect(() => {
    const remindersOutOfRange = compareDates();

    if (remindersOutOfRange.length > 0) {
      remindersOutOfRange.forEach((reminder, index) => {
        setError(`reminders[${index}].date`, {
          type: "custom",
          message: "El recordatorio sobrepasa los 3 días antes de la fecha de inicio",
        });
        setError(`reminders[${index}].time`, {
          type: "custom",
          message: "",
        });
        setError(`reminders[${index}].ampm`, {
          type: "custom",
          message: "",
        });
      });
      setShowWarningModal(true);
    } else {
      reminders.forEach((reminder, index) => {
        if (reminder) {
          clearErrors(`reminders[${index}].date`);
          clearErrors(`reminders[${index}].time`);
          clearErrors(`reminders[${index}].ampm`);
        }
      });
      setShowWarningModal(false);
    }
  }, [reminders, startDate, startTime, startAmpm]);

  useEffect(() => {
    const reminders = getValues("reminders");
    const defaultMessages = {
      Survey:
        "Hola Membeer ! recuerda que la mision {!NOMBRE-MISION¡} está activa hasta {!FECHA-INICIAL-MISION¡} y se te pagará {!VALOR-DE-PUNTOS-EN-DINERO¡} por participar.",
      VideoCall:
        "Hola Membeer ! recuerda que la video llamada {!NOMBRE-MISION¡} iniciará {!FECHA-INICIAL-MISION¡} a las {!HORA-INICIO-MISION¡} y se te pagará {!VALOR-DE-PUNTOS-EN-DINERO¡} por participar.",
    };

    reminders.forEach((reminder, index) => {
      if (!reminder.message) {
        const message = defaultMessages[typeTool];
        if (message) {
          setValue(`reminders[${index}].message`, message);
        }
      }
    });
  }, [typeTool]);

  useEffect(() => {
    reminders.forEach((reminder, index) => {
      if (reminder && reminder.message) {
        const openBrackets = (reminder.message.match(/{!/g) || []).length;
        const closeBrackets = (reminder.message.match(/¡}/g) || []).length;
        if (openBrackets !== closeBrackets) {
          setError(`reminders[${index}].message`, {
            type: "custom",
            message: "El mensaje debe contener un número par de corchetes abiertos y cerrados",
          });
        }
        else {
          clearErrors(`reminders[${index}].message`);
        }
      }
    });
  }, [reminders]);

  return {
    control,
    handleSubmit,
    onSubmit,
    showModal,
    saving,
    handleCloseModal,
    handleSubmitFilter,
    controlFilter,
    onSubmitFilter,
    controlSearch,
    listGroupMembeer,
    onChangeSearchGroup,
    rol,
    listCountries,
    listCurrencies,
    disableCloseNumberUser,
    handleChangeCloseUser,
    fields,
    append,
    remove,
    idTask,
    handleBack,
    disableFields,
    listProject,
    onChangeSearchProject,
    nameMission,
    disableFieldProyect,
    onSelectGroup,
    listSelectGroup,
    handleDeleteGroup,
    onChangeSearchTool,
    listTools,
    onSelectTool,
    listSelectTool,
    handleDeleteTool,
    fieldsPoint,
    fieldsConfig,
    disableFieldCountry,
    getValues,
    showWarningModal,
    toggleCloseShowWarningModal,
  };
};

export default useTaskCreate;
